// Lato Light
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.woff2') format('woff2'), url('../fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// Lato Light Italic
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-LightItalic.woff2') format('woff2'), url('../fonts/Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

// Lato Regular
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'), url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// Lato Italic
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Italic.woff2') format('woff2'), url('../fonts/Lato-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

// Lato Semibold
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Semibold.woff2') format('woff2'), url('../fonts/Lato-Semibold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Lato Semibold Italic
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-SemiboldItalic.woff2') format('woff2'), url('../fonts/Lato-SemiboldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

// Lato Bold
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'), url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Lato Bold Italic
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-BoldItalic.woff2') format('woff2'), url('../fonts/Lato-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

// Lato Black
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Black.woff2') format('woff2'), url('../fonts/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// Lato Black
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-BlackItalic.woff2') format('woff2'), url('../fonts/Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}