.marginBottomBig {
	margin-bottom: rem($baseGap * 1.25);

	@include breakpoint(large) {
		margin-bottom: rem($baseGap * 2);
	}

	@include breakpoint(extra) {
		margin-bottom: rem($baseGap * 2.5);
	}
}

.marginTop {
	margin-bottom: rem($baseGap / 1.5);

	@include breakpoint(large) {
		margin-bottom: rem($baseGap);
	}

	@include breakpoint(extra) {
		margin-bottom: rem($baseGap * 1.5);
	}
}

.marginBottom {
	margin-bottom: rem($baseGap / 1.5);

	@include breakpoint(large) {
		margin-bottom: rem($baseGap / 1.5);
	}
}

.marginBottomSmall {
	margin-bottom: rem($baseGap / 2);

	@include breakpoint(large) {
		margin-bottom: rem($baseGap / 1.5);
	}

	@include breakpoint(extra) {
		margin-bottom: rem($baseGap);
	}
}

.highlight {
	color: $secondary;
}

.table {
	display: table;

	&.centered {
		margin-left: auto;
		margin-right: auto;
	}
}

.noMargin {
	margin-bottom: 0;
}

@mixin btn-styles {
	position: relative;
	display: table;
	cursor: pointer;
	font-family: $mainFont;
	font-size: rem(18px);
	font-weight: 600;
	line-height: rem(24px);
	text-transform: uppercase;
	color: $dark;
	border: 2px solid $dark;
	padding: rem(8px) rem(14px) rem(5px);
	transition: opacity $transition, border $transition, color $transition, background-color $transition;

	&:hover, &:focus {
		color: $secondary;
		border: 2px solid $secondary;

		.icon {
			opacity: .65;
		}
	}

	&.light {
		color: $light;
		border: 2px solid $light;
	}

	&.centered {
		margin-left: auto;
		margin-right: auto;
	}

	&.full {
		color: $light;
		background-color: $dark;

		&:hover, &:focus {
			background-color: lighten($dark, 5%);
			border: 2px solid darken($dark, 5%);
		}

		&.primary {
			color: $light;
			background-color: $primary;
			border: 2px solid $primary;

			&:hover, &:focus {
				background-color: lighten($primary, 5%);
				border: 2px solid lighten($primary, 5%);
			}
		}
	}

	.icon {
		margin-left: rem(12px);
		margin-bottom: rem(-2.5px);
		transition: opacity $transition;
	}
}

@mixin btn-styles-ghost {
	position: relative;
	display: table;
	transition: color $transition, border $transition;

	&:hover, &:focus {
		color: $secondary;
		border: 2px solid $secondary;
	}
}

.btn {
	@include btn-styles;
}

.sectionPadding-none {
	padding-top: 0;
}

.sectionPadding-small {
	padding-top: rem(50px);

	@include breakpoint(large) {
		padding-top: rem(105px);
	}
}

.sectionPadding-default  {
	padding-top: rem(50px);

	@include breakpoint(large) {
		padding-top: rem(150px);
	}
}

.sectionPadding-big  {
	padding-top: rem(50px);

	@include breakpoint(large) {
		padding-top: rem(200px);
	}
}

.sectionMargin-none {
	margin-top: 0;
}

.sectionMargin-small {
	margin-top: rem(50px);

	@include breakpoint(large) {
		margin-top: rem(105px);

		&#privacyPolicy {
			margin-top: rem(70px);
		}
	}
}

.sectionMargin-default  {
	margin-top: rem(50px);

	@include breakpoint(large) {
		margin-top: rem(150px);
	}
}

.sectionMargin-big  {
	margin-top: rem(50px);

	@include breakpoint(large) {
		margin-top: rem(200px);
	}
}

.sectionMargin-negative  {
	margin-top: rem(-50px);

	@include breakpoint(large) {
		margin-top: rem(-105px);
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.videoContainer {
	&:not(.videoTag)
	{
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	video {
		width: 100%;
		height: auto;
	}
}
.googleMaps {
	@extend .videoContainer;

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
	$content: "default:" + $bpContext;
	width: str_length($content) * 6pt + $baseGap * 2;

	@each $point, $width in $gridMap {
		$content: $content +
		"...." +
		$point +
		":" +
		nth(map-get(map-get($gridMap, $point), width), 1);

		@include breakpoint($point) {
		width: str_length($content) * 6pt + $baseGap * 2;
		}
	}

	content: "#{$content}";
	display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
	background: $alert;
	border-color: $alert;
	border-style: solid;
	border-width: 0 rem($baseGap);
	color: $light;
	display: block !important;
	font-family: Courier;
	font-size: 10pt;
	left: 0;
	line-height: 2.5;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	white-space: nowrap;
	z-index: 99999;
	}
	&:hover:before {
	opacity: 0.5;
	pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.notificationArea
{
	position: fixed;
	bottom:20px;
	left:20px;
	z-index:999999;

	> :not(:last-child)
	{
		margin-bottom:$baseGap;
	}

	.notification
	{
		position: relative;
		transition:300ms all ease-in-out;
		overflow: hidden;
		padding: 1em;
		background: $alert;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
		width:330px;
		max-width:calc(100vw - 40px);
		transform-origin: left center;

		&, a
		{
			color: $light;
		}

		.close
		{
			position: absolute;
			top: 5px;
			right: 8px;
			cursor: pointer;
			color:#fff;
			font-size:1.2rem;
		}

		&.hide
		{
			opacity:0;
			visibility: hidden;
			transform:scale(0);
			max-height:0;
			height:0;
			padding-top:0;
			padding-bottom:0;
		}
	}
}