// Path to images folder
$imagePath: "../images/" !default;

// Font families
$mainFont: 'Lato', sans-serif;
$displayFont: 'Lato', sans-serif;
$iconFont: sans-serif;

// Font properties
$baseFontSize: 18px;
$baseLineHeight: 28px;
$hideTextDirection: right;

// Headline sizes
$h1Size: 48px;
$h2Size: 36px;
$h3Size: 24px;
$h4Size: 22px;
$h5Size: 20px;
$h6Size: 18px;

// Project colors
$primary: #6633FF; // Electric Purple 100
$primaryLight: #E0D6FF; // Electric Purple 30
$secondary: #FF5252; // Corall 100
$secondaryLight: #FFCBCB; // Corall 30
$light: #FDFDFD; // White
$medium: #F3F3F3; // Light Grey (Background)
$dark: #131416; // Dark Grey (Text)
$grey: #7F7F7F; // Grey (Text)
$alert: #FF5252; // Pink
$goldText: #E4C8B3; // Gold for text elements

// Define door colors
$blue: #27354F;
$blueLight: #D3D3D3;
$gold: #D8B093;
$goldLight: #FFE9D8;
$purple: #534998;
$purpleLight: #D1C2FF;
$corall: #FF6363;
$corallLight: #FFCBCB;

// Grid settings
$baseGap: 30px;
$rowMaxWidth: 1820px;
$columnCount: 12;

// Base styling
$baseBorder: 1px solid $secondary;

// Extras
$transition: .15s ease-in-out;
$letterSpacing: .1em;

// Important internals
$bpContext: 16px;

// Icon map
$iconMap: (
	times: "×",
	minus: "-",
	angle-right: "❯",
	plus: "+",
	angle-up: "↑",
	exclamation: "!"
);

// Breakpoint widths and classes
$gridMap: (
	default: (
		width: em(0px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	first: (
		width: em(375px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	tiny: (
		width: em(480px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	small: (
		width: em(640px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	medium: (
		width: em(760px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	large: (
		width: em(992px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	full: (
		width: em(1060px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	giant: (
		width: em(1200px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	huge: (
		width: em(1440px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	extra: (
		width: em(1600px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	final: (
		width: em(1820px, $bpContext),
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:flex-stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1)
	),
	cut: (
		width: em($rowMaxWidth, $bpContext),
		cols: (auto)
	),
);