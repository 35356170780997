// Form styles
form.default {
    overflow: hidden;
    width: 100%;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba($dark, .5);
        transition: color $transition;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: rgba($dark, .5);
        transition: color $transition;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: rgba($dark, .5);
        transition: color $transition;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: rgba($dark, .5);
        transition: color $transition;
    }

    .spacer {
        margin-bottom: rem($baseGap);
    }

    &:not(.no-grow) {

        fieldset {

            > div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                height: 100%;

                textarea {
                    display: flex;
                    align-items: flex-start;
                    flex-grow: 1;
                }

                input:last-of-type {

                    @include breakpoint(medium) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    fieldset {
        border: none;
        margin: 0;
    }

    input, textarea, select {
        min-height: rem(60px);

        &.no-margin {
            margin-bottom: 0;
        }
    }

    label {
        color: inherit;
        display: block;
        text-align: left;
        padding: rem(5px) 0 0;
        position:relative;

        small {
            display: inline-block;
            color: $primary;
        }

        &.checkbox:not(.error) {
            position: relative;
            display: block;
            cursor: pointer;
            font-size: rem(15px);
            line-height: 1.35em;
            color: rgba($dark, .75);
            padding: rem($baseGap / 2) rem(100px) rem($baseGap / 2) 0;
            margin-bottom: rem(25px);

            &::before, &::after {
                position: absolute;
                display: block;
                content: '';
                right: 0;
            }

            &::before {
                width: rem(60px);
                height: rem(30px);
                border: rem(2px) solid rgba($dark, .25);
                background-color: $light;
                border-radius: rem(30px);
                top: rem($baseGap/2);
                transition: all .3s ease-in-out;
            }

            &::after {
                width: calc(#{rem(30px)} - #{rem(2px)});
                height: calc(#{rem(30px)} - #{rem(2px)});
                background-color: $light;
                border-radius: 100%;
                right: calc(#{rem(30px)} + #{rem(1px)});
                top: calc(#{rem($baseGap/2)} + #{rem(1px)});
                box-shadow: 0 rem(2px) rem(5px) 0 rgba($dark, .25);
                transition: all .3s ease-in-out;
            }

            span {
                display: block;
            }

            small {
                display: inline-block;

                @include breakpoint(small) {
                    padding: rem(9px) 0 0 rem(5px);
                }
            }
        }

        &.error:not(#hiddenRecaptcha-error) {
            display: none !important;
        }

        &#hiddenRecaptcha-error {
            position: relative;
            display: block;
            font-size: rem(12px);
            line-height: 1em;
            color: $light;
            background-color: $alert;
            padding: rem(10px) rem(10px) rem(8px);
            margin-top: rem(17px);
            border-radius: rem(6px);

            &::before {
                position: absolute;
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-left: rem(8px) solid transparent;
                border-right: rem(8px) solid transparent;
                border-bottom: rem(8px) solid $alert;
                left: rem(19px);
                top: 0;
                transform: translateY(-100%);
            }
        }
    }

    .g-recaptcha {
        max-width: calc(100vw - #{rem($baseGap * 2)});
    }

    .input-ctn {
        margin-bottom: rem(5px);
    }

    legend {
        display: flex;
        width: 100%;
        min-height: 3.5rem;
        align-items: center;
        color: $light;
        font-weight: 300;
        background-color: $primary;
        padding: rem(15px) rem(20px) rem(13px) rem(25px);
        margin-bottom: rem(5px);
    }

    [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
        position: relative;
        display: block;
        width: 100%;
        max-width: 100%;
        font-family: $mainFont;
        font-size: 1rem;
        font-weight: normal;
        color: $dark;
        background-color: $light;
        padding: rem(5px) rem($baseGap) rem(3px) rem($baseGap);
        border: none;
        border-bottom: 1px solid rgba($dark, .35);
        outline: 0;
        margin-bottom: rem($baseGap);
        transition: all .3s ease-in-out;

        &:hover {
            border-bottom: 1px solid rgba($dark, .6);

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		        color: rgba($dark, .3);
		    }

		    &::-moz-placeholder { /* Firefox 19+ */
		        color: rgba($dark, .3);
		    }

		    &:-ms-input-placeholder { /* IE 10+ */
		        color: rgba($dark, .3);
		    }

		    &:-moz-placeholder { /* Firefox 18- */
		        color: rgba($dark, .3);
		    }
        }

        &:focus {
            border-bottom: 1px solid rgba($dark, 1);

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		        color: rgba($dark, .15);
		    }

		    &::-moz-placeholder { /* Firefox 19+ */
		        color: rgba($dark, .15);
		    }

		    &:-ms-input-placeholder { /* IE 10+ */
		        color: rgba($dark, .15);
		    }

		    &:-moz-placeholder { /* Firefox 18- */
		        color: rgba($dark, .15);
		    }
        }
    }

    [type="file"] {
        min-height: auto;
        padding: rem(10px);
        background-position: calc(120%) 20px;

        &.valid {
            background-position: calc(100% - 20px) 14px !important;
        }
    }

    textarea { /* fake line-height */
        min-height: rem(150px);
        padding-top: 0.9rem;
        $inputgroup-count: 3;
        margin-bottom: rem(5px);
    }

    [type="checkbox"] {
        position: relative !important;
        min-height: auto;
        border: 1px solid transparent;
        background-color: transparent;
        opacity: 0;
        margin: 0;

        &:checked + {

            label.error + label.checkbox:not(.error), label.checkbox:not(.error) {

                &::before {
                    background-color: $primary;
                }

                &::after {
                    right: rem(1px);
                }
            }
        }

        &.error + label.error + {

            label.checkbox:not(.error) {

                &::before {
                    background-color: $alert;
                }
            }
        }
    }

    [type="radio"] {
        clear: none;
        float: left;
        width: 15px;
        margin: .4rem .8rem 0 0;
        min-height: auto;
    }

    button, [type="submit"] {
        @extend .btn;
    }

    .errorContainer {
        position: relative;
    }
}