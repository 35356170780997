// COOKIE CONTAINER
//////////////////////////////
.cookieConsentContainer {
	position: fixed;
	pointer-events: all !important;
	bottom: 0;
	left: 0;
	right: 0;
	color: $dark;
	background-color: white;
	-webkit-box-shadow: 0 rem(-10px) rem(20px) 0 rgba(0, 0, 0, .1);
	-moz-box-shadow: 0 rem(-10px) rem(20px) 0 rgba(0, 0, 0, .1);
	box-shadow: 0 rem(-10px) rem(20px) 0 rgba(0, 0, 0, .1);
	transition: opacity $transition, visibility $transition;
	z-index: 9999;

	body.noJS & {
		display: none;
	}

	body.cookieConsentNotVisible & {
		opacity: 0;
		visibility: hidden;
	}

	&::before {
		position: absolute;
		display: block;
		pointer-events: none;
		content: '';
		bottom: 100%;
		height: 100vh;
		left: 0;
		right: 0;
		background-color: rgba($dark, .75);
	}

	.cookieDisclaimer {
		max-width: rem(500px);

		a {
			color: $primary;
			transition: color $transition;

			&:hover, &:focus {
				color: rgba($primary, .65);
			}
		}
	}

	@include breakpoint(medium) {
		padding: rem($baseGap * 2) 0;
	}

	> .row {
		justify-content: space-between;
		padding: rem($baseGap * 1.5) 0 rem($baseGap);

		@include breakpoint(large, max) {
			align-items: center;
			max-height: 80vh;
			overflow-y: auto;
		}
	}

	p {
		font-size: rem(15px);
		line-height: 1.3em;
		color: darken(#BDC3C7, 40%);
		margin-top: rem($baseGap / 2);
	}

	.consentSection {
		position: relative;

		@include breakpoint(medium, max) {
			margin-top: rem($baseGap);
		}

		@include breakpoint(large) {
			padding-top: rem(45px);
		}
	}

	.cookieConsentListenerGeneral {
		position: relative;
		margin-top: rem($baseGap);
		margin-bottom: rem($baseGap);
	}
}

// COOKIE CONSENT INPUT
//////////////////////////////
.cookieConsentListenerGeneral {

	.cookieSection {

		.cookieConsentSingleHolder {
			position: relative;
			display: flex;
			align-items: center;
			height: 45px;
			width: 100%;
			max-width: rem(332px);

			&.specialHolderLong {
				max-width: none;

				.cookieConsentLabel {

					small {
					    font-size: rem(16px);
					    font-weight: 300;
					    margin-right: rem(65px);
					    float: right;

						@include breakpoint(small, max) {
							display: none;
						}
					}

					@include breakpoint(tiny) {
						font-size: rem(28px);
					}
				}
			}

			input.cookieConsentListener {
				width: 1px;
				height: 1px;

				&:checked + .cookieConsentLabel {

					> span {
						background-color: $primary;

						&::before {
							left: 22px;
						}
					}
				}

				&:disabled + .cookieConsentLabel {
					cursor: not-allowed;
					pointer-events: all;

					> span {
						opacity: .5;
						cursor: not-allowed;
						pointer-events: all;
					}
				}
			}

			.cookieConsentLabel {
				position: relative;
				display: block;
				cursor: pointer;
				font-size: rem(20px);
				font-weight: 700;
				letter-spacing: -.5px;
				color: darken(#BDC3C7, 40%);
				width: 100%;

				> span {
					position: absolute;
					cursor: pointer;
					width: 45px;
					height: 25px;
					top: 0px;
					right: 0;
					border-radius: rem(30px);
					background-color: #869198;
					transition: background-color $transition;

					&::before {
						position: absolute;
						display: block;
						content: '';
						width: 21px;
						height: 21px;
						border-radius: 100%;
						top: 2px;
						left: 2px;
						background-color: white;
						-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
						-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
						box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
						transition: left $transition;
					}
				}
			}
		}
	}
}

// GENERAL BODY STYLING
body.cookieConsentVisible {
	pointer-events: none !important;
	overflow: hidden;
	padding-bottom: 375px;

	@include breakpoint(tiny) {
		padding-bottom: 315px;
	}

	@include breakpoint(small) {
		padding-bottom: 295px;
	}

	@include breakpoint(medium) {
		padding-bottom: 188px;
	}

	@include breakpoint(large) {
		padding-bottom: 193px;
	}

	@include breakpoint(giant) {
		padding-bottom: 225px;
	}
}


// ADDITIONAL STYLING
.hideMore {
	display: none;
	opacity: 0;
	visibility: hidden;

	&.visible {
		display: inline;
		opacity: 1;
		visibility: visible;
	}
}

.showMoreToggle {
	cursor: pointer;
	color: $primary;
	font-weight: 700;
	transition: color $transition;

	&:hover, &:focus {
		color: rgba($primary, .5);
	}
}

// ANIMATIONS
@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    } to {
        -ms-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    } to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    } to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}