// NAVIGATION
//////////////////////////////
#navigation {

	.navigationContainer {

		.navLang {
			display: flex;
			align-items: center;
			flex-shrink: 1;
			margin-top: rem(35px);

			@include breakpoint(large) {
				margin-top: 0;
				margin-left: rem(35px);
			}

			> li {

				&:nth-of-type(2) {
					font-weight: 300;
					color: $secondary;
					transform: translateY(-1px);
					margin: 0 rem(3.5px);
				}

				> a {
					display: block;
					font-size: rem(16px);
					line-height: 1em;
					color: $primary;
					transition: opacity $transition, color $transition;

					&:hover:not(.active), &:focus:not(.active) {
						opacity: .65;
					}

					&:not(.active) {
						font-weight: 700;
						color: $secondary
					}
				}
			}
		}
	}

	// NAVIGATION MOBILE
	//////////////////////////////
	@include breakpoint(large, max) {
		position: fixed;
		display: block;
		color: $primary;
		background-color: $light;
		top: rem(54px);
		bottom: 0;
		right: 0;
		width: rem(300px);
		padding: rem(45px) rem(30px);
		transform: translateX(100%);
		overflow-y: scroll;
		transition: transform $transition, -webkit-box-shadow $transition, -moz-box-shadow $transition, box-shadow $transition;
		z-index: 100;

		#menu-navigation, #menu-navigation-en {

			li {

				&:not(:last-of-type) {
					margin-bottom: rem(34px);
				}

				> a, > span {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					color: $dark;
					font-size: 18px;
					font-weight: 700;
					line-height: 1em;
					padding-left: rem(8px);
					transition: color $transition;
				}

				&.current-menu-item,
				&.current_page_item,
				&.current_page_ancestor,
				&.current-menu-ancestor,
				&.current-menu-parent,
				&.current_page_parent {

					> a, > span {
						position: relative;
						color: $primary;

						// &::before {
						// 	position: absolute;
						// 	content: '';
						// 	width: calc(100% + #{rem(4px)});
						// 	height: 1px;
						// 	left: rem(-2px);
						// 	top: 50%;
						// 	background-color: rgba($light, .75);
						// }
					}
				}
			}

			> li {

				ul.sub-menu {
					margin-top: rem(5px);

					> li {

						> a, > span {
							color: rgba($light, .75);
							font-size: rem(13px);
						}
					}
				}
			}
		}

		.brandingContainer {
			display: none;
		}
	}

	// NAVIGATION DESKTOP
	//////////////////////////////
	@include breakpoint(large) {
		display: flex;
		align-items: flex-end;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 100%;
		color: $dark;
		background-color: $light;
		padding-bottom: 0;
		z-index: 200;

		> .row {
			height: 100%;
		}

		.navigationContainer {
			display: flex;
			align-items: center;
    		justify-content: flex-end;

    		nav {

    			#menu-navigation, #menu-navigation-en {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding: 0 0 0 rem($baseGap);

					li {
						display: table;

						&:hover, &:focus {

							> a, > span {
								color: rgba($light, .5);
							}

							> .sub-menu {
								opacity: 1;
								visibility: visible;
								left: 50%;
							}
						}

						.sub-menu {
							position: absolute;
							display: block;
							min-width: rem(170px);
							padding-left: rem(20px);
							padding-right: rem(20px);
							border-bottom-right-radius: rem(5px);
							border-bottom-left-radius: rem(5px);
							background-color: $light;
							opacity: 0;
							visibility: hidden;
							left: 40%;
							top: rem(56px);
							transform: translateX(-50%);
							-webkit-box-shadow: 0 9px 36px 0 rgba(0, 0, 0, .15);
							-moz-box-shadow: 0 9px 36px 0 rgba(0, 0, 0, .15);
							box-shadow: 0 9px 36px 0 rgba(0, 0, 0, .15);
							transition: opacity $transition, visibility $transition, left $transition;

							&::before {
								position: absolute;
								content: '';
								width: 100%;
								height: rem(46px);
								left: 0;
								bottom: 100%;
								z-index: 10;
							}

							&::after {
								position: absolute;
								display: block;
								content: '';
								width: rem(100px);
								height: rem(5px);
								background-color: $secondary;
								border-top-left-radius: rem(5px);
								border-top-right-radius: rem(5px);
								left: 50%;
								transform: translateX(-50%);
								bottom: 100%;
							}

							> li {
								display: block;
								width: 100%;

								&:not(:last-of-type) {
									border-bottom: 1px solid $primary;
								}

								&.current-menu-item,
								&.current_page_item,
								&.current_page_ancestor,
								&.current-menu-ancestor,
								&.current-menu-parent,
								&.current_page_parent {

									> a, > span {
										color: $primary;
									}
								}

								> a, > span {
									display: block;
									width: 100%;
									white-space: nowrap;
									font-size: rem(12px);
									font-weight: 700;
									line-height: rem(18px);
									text-align: center;
									color: $primary;
									padding: rem($baseGap) 0;

									&:hover, &:focus {
										color: rgba($secondary, .65);
									}
								}
							}
						}
					}

					> li {
						position: relative;

						&.current-menu-item,
						&.current_page_item,
						&.current_page_ancestor,
						&.current-menu-ancestor,
						&.current-menu-parent,
						&.current_page_parent {

							> a, > span {
								color: $primary !important;
							}
						}

						&#menu-item-12, &#menu-item-15, &#menu-item-22, &#menu-item-25, &#menu-item-469, &#menu-item-468 {
							display: none;
						}

						&:not(:last-of-type) {
							margin-right: rem(45px);
						}

						&#menu-item-198, &#menu-item-382 {
							margin-right: 0;
						}

						&:hover, &:focus {

							> a, > span {
								color: rgba($dark, .65);
							}

							> .sub-menu {
								left: 50%;
								visibility: visible;
								opacity: 1;
							}
						}

						> a, > span {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							color: $dark;
							font-size: 18px;
							font-weight: 700;
							line-height: 1em;
							transition: color $transition;
						}
					}
				}
    		}
		}
	}
}

// QUICK BAR
//////////////////////////////
#quickbar {
	display: none;

	// MOBILE APPEARANCE
	//////////////////////////////
	@include breakpoint(large, max) {
		position: fixed;
		display: flex;
		justify-content: space-between;
		align-items: center;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: rem(54px);
		color: $light;
		background-color: darken($light, 2.5%);
		padding-left: rem($baseGap);
		transition: box-shadow $transition;
		z-index: 99;

		body.navigationFixed & {
			-webkit-box-shadow: 0 0 50px 0 rgba(24, 35, 51, .2);
			-moz-box-shadow: 0 0 50px 0 rgba(24, 35, 51, .2);
			box-shadow: 0 0 50px 0 rgba(24, 35, 51, .2);
		}

		.ziegert-branding {
			position: relative;
			display: block;
			width: 100%;
			max-width: rem(78px);
		}
	}
}

// NAVIGATION TOGGLER INPUT
//////////////////////////////
#naviToggled {
	display: none;
}

// DEFAULT NAVIGATION TOGGLER
//////////////////////////////
label[for="naviToggled"] {
	position: relative;
	display: block;
	top: 0;
	right: 0;
	width: rem(70px);
    height: rem(54px);
    cursor: pointer;
    z-index: 1001;

    span {
    	display: none;
    }

	i {
	    display: block;
	    position: absolute;
	    height: 2px;
	    width: 18px;
	    background: $dark;
	    opacity: 1;
	    left: 0;
	    transform: rotate(0deg);
	    transition: top $transition, left $transition, opacity $transition, transform $transition, width $transition;
	    z-index: 10;

	    &:nth-child(1) {
	    	top: rem(23px);
	    	left: rem(39px);
	    	width: 9px;
	    }

	    &:nth-child(2) {
	    	top: rem(28px);
	    	width: rem(18px);
	    	left: rem(30px);
	    }

	    &:nth-child(3) {
	    	top: rem(33px);
	    	width: rem(9px);
	    	left: rem(30px);
	    }
	}

	&::before {
		position: fixed;
		content: '';
		top: rem(54px);
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: rgba($dark, 0);
		visibility: hidden;
		transition: background-color $transition, visibility $transition, transform $transition;
	}
}

// CHECKED NAVIGATION TOGGLER
//////////////////////////////
#naviToggled {
	display: none;

	&:checked {

		~ #pageWrap {
   			height: 100vh;
   			overflow: hidden;
		}

		~ * #navigation, ~ #navigation {
			transform: translateX(0%);
		}

		~ * label[for="naviToggled"], ~ label[for="naviToggled"] {

			&::before {
				background-color: rgba(#000000, .65);
				visibility: visible;
			}

			&::after {
				background-color: $primary;
			}

			i {

				&:nth-child(1) {
				    top: rem(29px);
				    width: rem(21px);
				    left: rem(27px);
					transform: rotate(135deg);
			    }

			    &:nth-child(2) {
			    	opacity: 0;
    				left: rem(-60px);
			    }

			    &:nth-child(3) {
		    	    top: rem(29px);
		    	    width: rem(21px);
		    	    left: rem(27px);
    				transform: rotate(-135deg);
			    }
			}
		}
	}
}