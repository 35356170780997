// ROOT STYLING
//////////////////////////////
body {
	max-width: 100vw;
	overflow-x: hidden;

	&.overlayOpen {
		overflow-y: hidden;
	}
}

#root {
	position: relative;
	min-width: 100vw;
	min-height: 100vh;
	max-width: 100vw;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: image-url('layout/bg-grunge.jpg');
	overflow: hidden;

	.AppWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		min-height: 100vh;
		padding-left: rem($baseGap);
		padding-right: rem($baseGap);

		@include breakpoint(medium) {
			padding-left: rem($baseGap * 2);
			padding-right: rem($baseGap * 2);
		}

		@include breakpoint(large) {
			padding-left: rem($baseGap);
			padding-right: rem($baseGap);
		}

		@include breakpoint(extra) {
			flex-grow: 1;
			height: 100%;
		}
	}

	.App {
		margin-bottom: rem($baseGap * 1.5);

		@include breakpoint(large) {
			margin-bottom: rem(70px);
		}

		@include breakpoint(extra) {
			margin-bottom: rem(100px);
		}
	}

	* {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	    user-select: none;
	}
}

// LOADING
//////////////////////////////
.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 11, 11, .8);
    left: 0;
    top: 0;
    z-index: 2;

    &.white {
        background-color: white;
    }

    img {
        position: relative;
        width: rem(200px);
        height: rem(200px);
        background-color: transparent;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

		@include breakpoint(small) {
			width: rem(500px);
			height: rem(500px);
		}
    }
}

// GENERAL ADVENTCALENDAR
//////////////////////////////
.adventsCalender {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: rem(1735px);
	margin-left: auto;
	margin-right: auto;

	@include breakpoint(large) {
		width: 90vw;
	}

	.topBar {
		position: relative;
		display: flex;
		width: 100%;
		max-width: rem(1500px);
		margin-top: rem($baseGap * 2.5);
		margin-bottom: rem($baseGap * 1.5);
		justify-content: flex-end;

		@include breakpoint(medium, max) {
			flex-direction: column;
    		align-items: center;
		}

		@include breakpoint(large) {
			margin-top: rem(45px);
			margin-bottom: rem($baseGap);
			width: calc(100% - #{rem($baseGap * 2)});
		}

		@include breakpoint(final) {
			margin-top: rem(64px);
			margin-bottom: rem(54px);
		}

		.branding {
			position: absolute;
			display: block;
			height: 100%;
			max-height: rem(50px);
			width: rem(160px);
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			background-image: image-url('branding.svg');
			background-size: contain;
			background-position: center left;
			background-repeat: no-repeat;

			@include breakpoint(medium, max) {
			    position: relative;
			    height: rem(35px);
			    width: rem(110px);
			}
		}

		.headline {
			color: $goldText;
			font-family: $mainFont;
			font-style: italic;
			font-weight: 300;
			font-size: rem(26px);
			line-height: 1em;
			text-transform: uppercase;
			white-space: nowrap;

			@include breakpoint(tiny) {
				font-size: rem(30px);
			}

			@include breakpoint(large) {
				font-size: rem(35px);
			}

			@include breakpoint(extra) {
				font-size: rem(40px);
			}

			@include breakpoint(extra) {
				font-size: rem(50px);
			}

			b {
				font-weight: 900;
			}
		}
	}
}

// GRID DOOR STYLING
//////////////////////////////
.doors {
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, calc(50% - #{rem(10px)}));
	grid-template-rows: repeat(40, rem(40px));
	grid-auto-flow: row dense;
	gap: rem(20px);
	width: 100%;
	min-width: 100%;

	@include breakpoint(small) {
		grid-template-columns: repeat(3, calc((100% / 3) - #{rem(40px / 3)}));
		grid-template-rows: repeat(28, rem(40px));
	}

	@include breakpoint(large) {
		grid-template-columns: repeat(4, calc(25% - #{rem(15px)}));
		grid-template-rows: repeat(20, rem(50px));
	}

	@include breakpoint(giant) {
		grid-template-columns: repeat(6, calc((100% / 6) - #{rem(100px / 6)}));
		grid-template-rows: repeat(14, rem(50px));
	}

	@include breakpoint(huge) {
		grid-template-columns: repeat(8, calc((100% / 8) - #{rem(140px / 8)}));
		grid-template-rows: repeat(10, rem(40px));
		gap: rem(20px);
	}

	@include breakpoint(extra) {
		grid-template-columns: repeat(8, calc((100% / 8) - #{rem(210px / 8)}));
		grid-template-rows: repeat(10, rem(52.5px));
		gap: rem(30px);
	}

	@include breakpoint(final) {
		grid-template-rows: repeat(10, rem(61px));
		gap: rem(30px);
	}

	.door {

		// Small
		&.num-09, &.num-14, &.num-15, &.num-21 {
			grid-row-end: span 2;
		}

		// Medium
		&.num-01, &.num-03, &.num-04, &.num-05, &.num-11, &.num-12, &.num-16, &.num-17, &.num-18, &.num-20, &.num-22, &.num-23 {
			grid-row-end: span 3;
		}

		// Big
		&.num-02, &.num-07, &.num-08, &.num-10, &.num-13, &.num-19 {
			grid-row-end: span 4;
		}

		// Big double
		&.num-06, &.num-24 {
			grid-row-end: span 3;
			grid-column-end: span 2;
		}

		// Fixes
		@include breakpoint(small, max) {

			&.num-14 {
				grid-column: 1;
			}

			&.num-21 {
				grid-column: 2;
			}
		}

		@include breakpoint(small) {
			@include breakpoint(large, max) {

				&.num-22 {
					grid-column: 1;
				}

				&.num-16 {
					grid-column: 2;
				}

				&.num-14, &.num-15 {
					grid-row-end: span 4;
				}
			}
		}

		@include breakpoint(large) {
			@include breakpoint(giant, max) {

				&.num-05 {
					grid-column: 3;
				}

				&.num-21 {
					grid-column: 2;
				}

				&.num-02, &.num-03, &.num-04, &.num-05 {
					grid-row-end: span 2;
				}

				&.num-14 {
					grid-row-end: span 3;
				}

				&.num-01, &.num-12, &.num-16 {
					grid-row-end: span 4;
				}

				&.num-19 {
					grid-row-end: span 5;
				}
			}
		}

		@include breakpoint(giant) {
			@include breakpoint(huge, max) {

				&.num-05, &.num-08, &.num-18, &.num-21 {
					grid-row-end: span 2;
				}

				&.num-03, &.num-08, &.num-14 {
					grid-row-end: span 3;
				}

				&.num-21 {
					grid-row-end: span 4;
				}

				&.num-07, &.num-11, &.num-19 {
					grid-row-end: span 5;
				}
			}
		}

		@include breakpoint(huge) {

			&.num-02 {
				grid-row-start: 7;
				grid-row-end: 11;
			}

			&.num-08 {
				grid-column-start: 8;
				grid-column-end: 9;
				grid-row-start: 4;
				grid-row-end: 8;
			}

			&.num-11 {
				grid-column-start: 3;
				grid-column-end: 4;
				grid-row-start: 8;
				grid-row-end: 11;
			}

			&.num-12 {
				grid-column-start: 6;
				grid-column-end: 7;
				grid-row-start: 4;
				grid-row-end: 7;
			}

			&.num-14 {
				grid-column-start: 6;
				grid-column-end: 7;
				grid-row-start: 9;
				grid-row-end: 11;
			}

			&.num-18 {
				grid-column-start: 2;
				grid-column-end: 3;
				grid-row-start: 8;
				grid-row-end: 11;
			}

			&.num-21 {
				grid-column-start: 6;
				grid-column-end: 7;
				grid-row-start: 7;
				grid-row-end: 9;
			}

			&.num-22 {
				grid-row-start: 4;
				grid-row-end: 7;
			}

			&.num-24 {
				grid-column-start: 7;
				grid-column-end: 9;
				grid-row-start: 8;
				grid-row-end: 11;
			}
		}
	}

	.houseHolder {
		position: absolute;
		display: none;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;

		@include breakpoint(large) {
			display: block;
		}

		&::before, &::after {
			position: absolute;
			pointer-events: none;
			content: '';
			height: 100%;
			width: 100vw;
			top: 0;
			bottom: 0;
			z-index: 1;
			background-repeat: no-repeat;
			background-size: contain;
		}

		&::before {
			min-width: rem(1609px);
			background-image: image-url('layout/bg-house-left.png');
			background-position: top right;
			right: calc(100% + #{rem($baseGap)});

			@include breakpoint(final) {
				right: calc(100% + #{rem($baseGap * 1.5)});
			}
		}

		&::after {
			min-width: rem(1589px);
			background-image: image-url('layout/bg-house-right.png');
			background-position: top left;
			left: calc(100% + #{rem($baseGap)});

			@include breakpoint(final) {
				left: calc(100% + #{rem($baseGap * 1.5)});
			}
		}

		.peopleHolder {
			position: absolute;
			width: rem(1500px);
			left: 50%;
			top: calc(100% - #{rem(100px)});
			transform: translateX(-50%);
			z-index: 2;

			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 0;
				padding-bottom: 12.09%;
				background-image: image-url('layout/bg-people.png');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
}

// GENERAL DOOR STYLING
//////////////////////////////
.door {
	position: relative;
	color: transparent;
	background-color: $dark;
	border: 0;
	border-radius: rem(17px);

	&.active:not(.clicked) {

		&:hover, &:focus {

			.doorWrap {
				transform: perspective(1750px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-15deg);
			}
		}

		&.num-06, &.num-24 {

			&:hover, &:focus {

				.doorWrap {
					transform: perspective(2400px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-15deg);
				}
			}
		}

		&.num-09, &.num-14, &.num-15, &.num-21 {

			&:hover, &:focus {

				.doorWrap {
					transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-15deg);
				}
			}
		}
	}

	&.startAnimation {
		z-index: 2;

		.doorWrap {
			transform: perspective(1750px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-105deg);
			transition: transform .75s ease-in-out;

			.outerDoor {
				background-color: $dark;
			}
		}
	}

	&.notActive {
		cursor: not-allowed;
	}

	&.clicked:not(.startAnimation) {
		opacity: .95;

		&.num-06, &.num-24 {

			.doorWrap {
				transform: perspective(2400px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-28deg);
				transition: none;

				@include breakpoint(large) {
					transform: perspective(2400px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-35deg);
				}
			}
		}

		.doorWrap {
			transform: perspective(2400px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-30deg);
			transition: none;

			@include breakpoint(large) {
				transform: perspective(2400px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-42deg);
			}

			.innerDoor {

				&::after {
					background-color: $dark;
					background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23D3D3D3' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e");
				}
			}
		}

		.innerContainer {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			background-image: image-url('layout/bg-kinked-blue.png');
		}

		&.num-03, &.num-05, &.num-08, &.num-17, &.num-22 {

			.doorWrap {

				.innerDoor {

					&::after {
						background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFCBCB' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e");
						background-color: $dark;
					}
				}
			}

			.innerContainer {
				background-image: image-url('layout/bg-kinked-corall.png');
			}
		}

		&.num-01, &.num-02, &.num-07, &.num-15 {

			.doorWrap {

				.innerDoor {

					&::after {
						background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFE9D8' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e");
						background-color: $dark;
					}
				}
			}

			.innerContainer {
				background-image: image-url('layout/bg-kinked-gold.png');
			}
		}

		&.num-04, &.num-09, &.num-10, &.num-18, &.num-19, &.num-21, &.num-24 {

			.doorWrap {

				.innerDoor {

					&::after {
						background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23D1C2FF' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e");
						background-color: $dark;
					}
				}
			}

			.innerContainer {
				background-image: image-url('layout/bg-kinked-purple.png');
			}
		}
	}

	.doorWrap {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		cursor: pointer;
		color: $primary;
		background-color: transparent;
		border: 0;
		border-radius: rem(17px);
		transform-origin: left;
		transition: transform .3s ease-in-out;
	}

	.outerDoor {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: rem(17px);
		z-index: -1;
	}

	.innerDoor {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;

		&::before {
			position: absolute;
			display: block;
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: rem(15px);
			pointer-events: none;
		}

		&::after {
			position: absolute;
			content: '';
			width: rem(40px);
			height: rem(40px);
			border-radius: 100%;
			top: 50%;
			right: 0;
			transform: translate(50%, -50%);
			z-index: 1;
		}

		.innerContainer {
			position: relative;
			display: block;
			width: calc(100% - #{rem(2px)});
			height: calc(100% - #{rem(2px)});
			margin: rem(1px);
			background-color: $primary;
			border-radius: rem(15px);

			.number {
				position: absolute;
				display: table;
				font-size: rem(42px);
				font-family: $mainFont;
				font-weight: 300;
				font-style: italic;
				line-height: rem(50px);
				letter-spacing: -.075em;

				@include breakpoint(huge) {
					@include breakpoint(extra, max) {
						font-size: rem(36px);
						line-height: 1em;
					}
				}
			}
		}
	}

	// Blue doors
	&.num-06, &.num-11, &.num-12, &.num-13, &.num-14, &.num-16, &.num-20, &.num-23 {

		.innerDoor {
			@extend .doorBlue;
		}
	}

	// Gold doors
	&.num-01, &.num-02, &.num-07, &.num-15 {

		.innerDoor {
			@extend .doorGold;
		}

		// Package styling
		&.num-02, &.num-07 {

			.doorWrap {

				&::before, &::after {
					background-color: $goldLight;
				}
			}
		}
	}

	// Purple doors
	&.num-04, &.num-09, &.num-10, &.num-18, &.num-19, &.num-21, &.num-24 {

		.innerDoor {
			@extend .doorPurple;
		}

		// Package styling
		&.num-04, &.num-10, &.num-19 {

			.doorWrap {

				&::before, &::after {
					background-color: $purpleLight;
				}
			}
		}
	}

	// Corall doors
	&.num-03, &.num-05, &.num-08, &.num-17, &.num-22 {

		.innerDoor {
			@extend .doorCorall;
		}

		// Package styling
		&.num-05, &.num-08, &.num-17 {

			.doorWrap {

				&::before, &::after {
					background-color: $corallLight;
				}
			}
		}
	}

	// Package doors
	&.num-02, &.num-04, &.num-05, &.num-07, &.num-08, &.num-10, &.num-11, &.num-12, &.num-13, &.num-17, &.num-19 {

		.doorWrap {

			&::before, &::after {
				position: absolute;
				display: block;
				content: '';
				border-radius: rem(5px);
				background-color: $blueLight;
				z-index: 2;
				pointer-events: none;

				@include breakpoint(extra) {
					border-radius: rem(8px);
				}
			}

			&::before {
				height: calc(100% + #{rem(8px)});
				width: rem(6px);
				top: rem(-4px);
				right: rem(35px);

				@include breakpoint(extra) {
					width: rem(9px);
					right: rem(56px);
				}
			}

			&::after {
				width: calc(100% + #{rem(8px)});
				height: rem(6px);
				left: rem(-4px);
				top: rem(35px);

				@include breakpoint(extra) {
					height: rem(9px);
					top: rem(56px);
				}
			}
		}

		.innerDoor {

			.innerContainer {

				.number {
					left: rem(25px);
					bottom: rem(23px);

					@include breakpoint(huge) {
						@include breakpoint(final, max) {
							left: rem(18px);
							bottom: rem(15px);
						}
					}
				}
			}
		}
	}

	// Big doors
	&.num-06, &.num-24 {

		.innerDoor {

			&::after {
				top: auto;
				bottom: rem(9px);
			}

			.innerContainer {

				&::before {
					position: absolute;
					content: '';
					pointer-events: none;
					top: 0;
					left: 0;
					right: 0;
					border-top-right-radius: rem(17px);
					border-top-left-radius: rem(17px);
					background-image: image-url('layout/bg-lines-blue.svg');
					background-size: cover;
					background-repeat: no-repeat;
					background-position: top left;
					bottom: rem(100px);
					z-index: 1;
				}

				.number {
					left: rem(25px);
					bottom: rem(24px);

					@include breakpoint(huge) {
						@include breakpoint(final, max) {
							left: rem(18px);
							bottom: rem(15px);
						}
					}
				}
			}
		}

		&.num-24 {

			.innerDoor {

				.innerContainer {

					&::before {
						background-image: image-url('layout/bg-lines-purple.svg');
					}
				}
			}
		}
	}

	// Small doors
	&.num-09, &.num-14, &.num-15, &.num-21 {

		.innerDoor {

			.innerContainer {

				.number {
					left: rem(25px);
					bottom: rem(24px);
				}
			}
		}
	}

	// Light door medium
	&.num-01, &.num-03, &.num-16, &.num-18, &.num-20, &.num-22, &.num-23, &.num-14, &.num-15 {

		.innerDoor {

			&::after {
				top: 37%;

				@include breakpoint(huge) {
					top: 42%;
				}
			}

			.innerContainer {

				.number {
					left: rem(25px);
					top: 25.24%;

					@include breakpoint(huge) {
						top: 30%;
					}

					&::before {
						position: absolute;
						display: block;
						pointer-events: none;
						content: '';
						width: rem(199px);
						height: rem(180px);
						left: rem(-30px);
						top: rem(-37px);
						background-position: center top;
						background-repeat: no-repeat;
						background-image: image-url('layout/bg-lights-corall.svg');
						background-size: 100%;
						z-index: 1;

						@include breakpoint(huge, max) {
							min-width: rem(230px);
							min-height: rem(230px);
							top: rem(-45px);

							@include breakpoint(giant, max) {
								top: -75%;
							}
						}
					}
				}
			}
		}

		&.num-18 {

			.innerDoor {

				.innerContainer {

					.number {

						&::before {
							background-image: image-url('layout/bg-lights-purple.svg');
						}
					}
				}
			}
		}

		&.num-01, &.num-16, &.num-20, &.num-23 {

			.innerDoor {

				.innerContainer {

					.number {

						&::before {
							background-image: image-url('layout/bg-lights-blue.svg');
						}
					}
				}
			}
		}
	}

	// Light door small
	&.num-14, &.num-15 {

		@include breakpoint(huge) {

			.innerDoor {

				.innerContainer {

					.number {
						position: absolute;
						top: auto;

						&::before {
							position: absolute;
							display: block;
							pointer-events: none;
							content: '';
							width: rem(191px);
							height: rem(151px);
							left: rem(-27px);
							top: rem(-77px);
							background-position: center top;
							background-repeat: no-repeat;
							background-image: image-url('layout/bg-lights-small-blue.svg');
							background-size: 100%;
							z-index: 1;

							@include breakpoint(giant, max) {
								min-width: rem(300px);
								min-height: rem(350px);
								top: -120px;
							}
						}
					}
				}
			}

			&.num-15 {

				.innerDoor {

					.innerContainer {

						.number {

							&::before {
								background-image: image-url('layout/bg-lights-small-gold.svg');
							}
						}
					}
				}
			}
		}
	}

	// Stars door small
	&.num-09, &.num-21 {

		.innerDoor {

			.innerContainer {

				.number {

					&::before {
						position: absolute;
						display: block;
						pointer-events: none;
						content: '';
						width: rem(157px);
						height: rem(122px);
						left: rem(-12px);
						top: rem(-65px);
						background-position: center top;
						background-repeat: no-repeat;
						background-image: image-url('layout/bg-stars-purple.svg');
						background-size: 100%;
						z-index: 1;
					}
				}
			}
		}
	}
}

// DEFINE DOOR STYLINGS
//////////////////////////////
.doorBlue {

	&::before {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='17' ry='17' stroke='%23D3D3D3' stroke-width='3' stroke-dasharray='5%2c 11' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
	}

	&::after {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23D3D3D3' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e"), image-url('layout/bg-circle.jpg');
	}

	.innerContainer {
		color: $blueLight;
		background-color: $blue;
	}
}

.doorGold {

	&::before {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='17' ry='17' stroke='%23FFE9D8' stroke-width='3' stroke-dasharray='5%2c 11' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
	}

	&::after {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFE9D8' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e"), image-url('layout/bg-circle.jpg');
	}

	.innerContainer {
		color: $goldLight;
		background-color: $gold;
	}
}

.doorPurple {

	&::before {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='17' ry='17' stroke='%23D1C2FF' stroke-width='3' stroke-dasharray='5%2c 11' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
	}

	&::after {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23D1C2FF' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e"), image-url('layout/bg-circle.jpg');
	}

	.innerContainer {
		color: $purpleLight;
		background-color: $purple;
	}
}

.doorCorall {

	&::before {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='17' ry='17' stroke='%23FFCBCB' stroke-width='3' stroke-dasharray='5%2c 11' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
	}

	&::after {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFCBCB' stroke-width='3' stroke-dasharray='5%2c 10.75' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e"), image-url('layout/bg-circle.jpg');
	}

	.innerContainer {
		color: $corallLight;
		background-color: $corall;
	}
}

// OVERLAY STYLING
//////////////////////////////
.overlay {
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($dark, 1);
	opacity: 0;
	visibility: hidden;
	z-index: 100;

	&.inScope {
		display: flex !important;
	}

	&.open {
		opacity: 1;
		visibility: visible;
		transition: opacity .8s ease-in-out, visibility .8s ease-in-out;
	}

	&.closing {
		opacity: 0;
		visibility: hidden;
		transition: opacity .8s ease-in-out, visibility .8s ease-in-out;
	}

	.closeOverlay:not(.single) {
		position: absolute;
		cursor: pointer;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: transparent;
		border: 0;
		z-index: -1;

		&:focus {
			outline: 0 !important;
			border: 0 !important;
		}
	}

	.row {
		justify-content: center;
		max-width: rem(1200px);
		height: 100%;
		padding: rem($baseGap * 1.5) 0;
		overflow-y: auto;
		background-image: image-url('layout/bg-grunge.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 2;

		@include breakpoint(small) {
			padding: rem($baseGap * 1.5) rem($baseGap);
		}

		@include breakpoint(medium) {
			height: 85vh;
			width: 72.5vw;
			max-width: rem(750px);
			border: rem(10px) solid $goldText;
		}

		@include breakpoint(large) {
			width: 65vw;
		}

		@include breakpoint(giant) {
			max-height: 80vh;
			width: 57.5vw;
			padding: rem($baseGap * 1.5) rem(50px) rem(50px);
		}

		@include breakpoint(huge) {
			width: 70vw;
			border: rem(20px) solid $goldText;
		}

		@include breakpoint(extra) {
			padding: rem($baseGap * 1.5) rem(100px) rem(100px);
		}

		@include breakpoint(final) {
			max-width: rem(925px);
		}

		.closeOverlay {
			position: fixed;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			right: 0;
			width: rem($baseGap * 1.5);
			height: rem($baseGap * 1.5);
			color: $goldLight;
			background-color: $gold;
			border: 0;
			z-index: 10;

			&:focus {
				outline: 0 !important;
				border: 0 !important;
			}

			&::before {
				display: block;
				content: '';
				width: rem(20px);
				height: rem(20px);
				background-image: image-url('layout/bg-xmark.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}

		* {
			color: $goldText;
		}
	}

	.image {
		position: relative;
		margin-top: rem($baseGap * -1.5);
		margin-left: rem(-$baseGap);
		margin-right: rem(-$baseGap);
		width: calc(100% + #{rem($baseGap * 2)});
		height: 0;
		padding-bottom: calc(100% / 2);
		@extend .marginBottomBig;

		@include breakpoint(small) {
			margin-left: rem(-$baseGap * 2);
			margin-right: rem(-$baseGap * 2);
			width: calc(100% + #{rem($baseGap * 4)});
		}

		@include breakpoint(large) {
			padding-bottom: calc(100% / 2.25);
		}

		@include breakpoint(giant) {
			margin-left: rem(-80px);
			margin-right: rem(-80px);
			width: calc(100% + #{rem(160px)});
		}

		@include breakpoint(huge) {
			margin-left: rem(-50px - $baseGap);
			margin-right: rem(-50px - $baseGap);
			width: calc(100% + #{rem(50px * 2 + $baseGap * 2)});
		}

		@include breakpoint(extra) {
			margin-left: rem(-100px - $baseGap);
			margin-right: rem(-100px - $baseGap);
			width: calc(100% + #{rem(100px * 2 + $baseGap * 2)});
			padding-bottom: calc(100% / 1.75);
		}

		picture {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			source, img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}

	.headline {
		display: block;
		font-size: rem(26px);
		font-style: italic;
		font-weight: 900;
		line-height: 1.15em;
		text-transform: uppercase;
		@extend .marginBottomSmall;

		@include breakpoint(small) {
			font-size: rem(30px);
		}

		@include breakpoint(medium) {
			font-size: rem(34px);
		}

		@include breakpoint(large) {
			font-size: rem(38px);
		}

		@include breakpoint(final) {
			font-size: rem(45px);
		}

		span {
			display: block;
			font-weight: 300;
			font-size: .85em;
		}
	}

	.editorContent {

		> * {
			font-weight: 300;
			font-style: italic;
			@extend .marginBottom;

			@include breakpoint(small, max) {
				font-size: .95em;
				line-height: 1.5em;
			}

			@include breakpoint(huge) {
				font-size: 1.15em;
			}
		}

		b, strong {
			font-weight: 400;
			font-size: 1em;
		}

		ul {

			li {
				position: relative;
				padding-left: rem($baseGap / 2);

				&::before {
					position: absolute;
					display: block;
					content: '';
					width: rem(5px);
					height: rem(5px);
					background-color: $goldText;
					border-radius: rem(5px);
					left: 0;
					top: .64em;

					@include breakpoint(small) {
						top: .55em;
					}
				}
			}
		}
	}

	.trennstrich {
		display: block;
		margin: rem($baseGap * 1.5) 0;
		width: 100%;
		height: rem(5px);
		background-color: $goldText;

		@include breakpoint(giant) {
			margin: rem($baseGap * 2.5) 0 rem($baseGap * 2.25);
		}
	}
}

// SNOWFLAKES
//////////////////////////////
.snowFlakeHolder {
	position: absolute;
	pointer-events: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}
.snowflake {
	--size: 1vw;
	width: var(--size);
	height: var(--size);
	background: white;
	border-radius: 50%;
	position: absolute;
	pointer-events: none;
	top: -5vh;
	z-index: 200;
}

@keyframes snowfall {
	0% {
		transform: translate3d(var(--left-ini), 0, 0);
	}
	100% {
		transform: translate3d(var(--left-end), 110vh, 0);
	}
}

@for $i from 1 through 50 {

	.snowflake:nth-child(#{$i}) {
		--size: #{random(5) * 0.2}vw;
		--left-ini: #{random(20) - 10}vw;
		--left-end: #{random(20) - 10}vw;
		left: #{random(100)}vw;
		animation: snowfall #{5 + random(10)}s linear infinite;
		animation-delay: -#{random(10)}s;
	}
}

.snowflake:nth-child(6n) {
	filter: blur(1px);
}

// ERROR HANDLER STYLING
//////////////////////////////
.errorHandler {
	position: fixed;
	bottom: rem(50px);
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none;
	z-index: 100;

	&::before {
		position: absolute;
		content: '';
		width: 200vw;
		height: calc(100% + #{rem(150px)});
		background: rgb(19,20,22);
		background: linear-gradient(0deg, rgba(19,20,22,1) 0%, rgba(19,20,22,0) 100%);
		left: 50%;
		top: rem(-100px);
		transform: translateX(-50%);
		z-index: -1;
	}

	.message {
		display: block;
		font-family: $mainFont;
		font-style: italic;
		line-height: 1.25em;
		text-align: center;
		color: $corallLight;
		background-color: transparent;
		padding: rem(18px) rem(25px);
		border-radius: rem(10px);
		box-shadow: 0 0 rem(50px) 0 rgba(#131416, .25);
		overflow: hidden;

		&::before {
			position: absolute;
			content: '';
			width: calc(100% + #{rem(2px)});
			height: calc(100% + #{rem(2px)});
			top: rem(-1px);
			left: rem(-1px);
			background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23FFE9D8' stroke-width='3' stroke-dasharray='5%2c 10' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
		}

		&::after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: $corall;
			border-radius: rem(10px);
			z-index: -1;
		}
	}
}