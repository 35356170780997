// GENERAL RESETS
//////////////////////////////
* {
    margin: 0;
    padding: 0;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;

    &, &:before, &:after {
        box-sizing: inherit;
    }
}

// SELECTION APPEARANCE
//////////////////////////////
::-webkit-scrollbar {
    width: rem(5px);
    height: rem(5px);
}

::-webkit-scrollbar-track {
    background: $blue;

    @include breakpoint(large) {
        background: $blue;
    }
}

::-webkit-scrollbar-thumb {
    background: $goldText;
}

// HTML BASICS
//////////////////////////////
html {
    box-sizing: border-box;
    color: $dark;
    font-family: $mainFont;
    font-size: 100% * ($baseFontSize / 16px);
    font-weight: 400;
    hyphens: manual;
    line-height: rem($baseLineHeight);
    text-size-adjust: none;
}

body {
    line-height: rem($baseLineHeight);
    background: $light;
    opacity: 1 !important;

        &.noJS * {
            transition: none !important;

        &::before, &::after {
            transition: none !important;
        }
    }
}

// HEADLINES
//////////////////////////////
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    text-rendering: optimizeLegibility;
}

h1, .h1 {
    position: relative;
    display: table;
    font-family: $displayFont;
    font-weight: 600;
    font-size: rem(28px);
    line-height: 1.05em;
    text-transform: uppercase;
    padding: .3em 0;
    margin-bottom: rem($baseGap);

    @include breakpoint(medium) {
        font-size: rem(36px);
    }

    @include breakpoint(medium) {
        font-weight: 700;
    }

    @include breakpoint(large) {
        font-size: rem(48px);
        line-height: 1.05em;
    }

    &.primary {
        color: $primary;
    }
}

h2, .h2 {
    position: relative;
    display: table;
    font-family: $displayFont;
    font-weight: 600;
    font-size: rem(24px);
    line-height: 1.05em;
    padding: .3em 0;
    margin-bottom: rem($baseGap / 2);

    @include breakpoint(medium) {
        font-size: rem(30px);
        font-weight: 700;
    }

    @include breakpoint(large) {
        font-size: rem($h2Size);
    }

    &.primary {
        color: $primary;
    }
}

h3, .h3 {
    position: relative;
    display: table;
    font-family: $displayFont;
    font-size: rem(24px);
    font-weight: 600;
    line-height: 1.05em;
    color: $dark;
    padding: .265em 0;

    @include breakpoint(large) {
        font-size: rem($h3Size);
    }

    &.primary {
        color: $primary;
    }
}

h4, .h4 {

    &:not(.sectionHeadline) {
        position: relative;
        display: block;
        font-size: rem(18px);
        font-weight: 700;
        line-height: 1.333333em;
        hyphens: none;
        color: $primary;
        margin-bottom: rem($baseLineHeight);

        @include breakpoint(small) {
            margin-bottom: rem(30px);
        }

        &.light {
            color: $light;
        }

        &.big {
            font-weight: 400;

            @include breakpoint(small) {
                font-size: rem(24px);
            }
        }
    }
}

h5, .h5 {
}

h6, .h6 {
}

// HORIZONTAL LINE
//////////////////////////////
hr, .hr {
    display: flex;
    background: rgba($primary, .35);
    border: none;
    clear: both;
    height: rem(2px);
    margin: rem($baseGap * 2) 0 rem(($baseGap * 2) + $baseLineHeight);
    flex-basis: 100%;
}

// RESPONSIVE IMAGES
//////////////////////////////
img {

    &:not(.cover) {
        vertical-align: bottom;
        float: none;
        height: auto;
        max-width: 100%;
        width: 100%;
    }

    &.cover {
        height: 100%;
        object-fit: cover;
    }
}

// LIST STYLES
//////////////////////////////
ul {
    list-style: none;

    &.styledList {

        li {
            position: relative;
            line-height: 1.5em;
            padding-left: rem($baseGap - 3px);

            @include breakpoint(large, max) {
                font-size: rem(16px);
            }

            &::before {
                position: absolute;
                display: block;
                content: '';
                width: rem(5px);
                height: rem(5px);
                border-radius: 50%;
                background-color: $dark;
                top: rem(9px);
                left: 0;
            }
        }
    }
}

// DEFINITION LIST
//////////////////////////////
dl {

    &.styledList {
        @include pie-clearfix;

        dt, dd {
            display: block;
        }

        dt {
            padding-bottom: 0;
            font-weight: 700;

            @include breakpoint(small) {
                min-width: rem(85px);
                float: left;
            }
        }

        dd {
            padding-top: 0;
            margin-bottom: rem(9px);

            @include breakpoint(small) {

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @include breakpoint(huge) {
            margin-bottom: rem($baseLineHeight);

            &.left, &.right {
                width: calc(100% + #{rem($baseGap)} - #{rem(5px)});
            }

            &.right {
                margin-left: calc(#{rem(-$baseGap)} + #{rem(5px)});
            }
        }
    }

    &.noMargin {
        margin-bottom: 0;

        @include breakpoint(huge) {

            &:not(.all) {
                margin-bottom: rem($baseLineHeight);
            }

            &.all {
                margin-bottom: rem(9px);
            }
        }
    }

    &.float-list {
        @include pie-clearfix;

        dt, dd {
            display: block;
            vertical-align: top;
            float: left;
        }

        dt {
            padding-right: .5rem;
            width: 40%;
            clear: left;
        }

        dd {
            width: 60%;
        }
    }
}

// ADDRESS RESET
//////////////////////////////
address {
    font-style:normal;
}

// DEFAULT IFRAME STYLING
//////////////////////////////
iframe {
    border: none;
}

// GENERAL LINKS
//////////////////////////////
a {
    color: $primary;
    text-decoration: none;
    transition: color $transition;

    body.page-template-defaults & {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    &:hover, &:focus {
        color: rgba($primary, .5);
    }
}

// GENERAL STYLING
//////////////////////////////
b, strong {
    font-weight: 700;
}